/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable value-no-vendor-prefix */
import React, { useEffect, useMemo, useCallback, useState, useRef } from 'react';
import { Link, Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { Avatar, Dropdown, Menu, Badge, Tabs, Tooltip, Modal, Button, message } from 'antd';
import { Icon } from '../../common/schema-form/common';
import copy from 'copy-to-clipboard';
import { get, omitBy } from 'lodash';
import styled from '@emotion/styled';
import { matchPath, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import AppLayout from '../../common/app-layout/app-layout.component';
import OrganisationApp from '../../../store/types/organisation-app';
import Organisation from '../../../store/types/organisation';
import AppDetailsContainer from './app-details/app-details.container';
import ErrorView from '../../common/error-view/error-view.component';
import Overlay from '../../common/overlay/overlay.component';
import ClassicDeviceDetailsContainer from './device-details/classic-device-details/device-details.container';
import AzureDeviceDetailsContainer from './device-details/azure-device-details/azure-device-details.container';
import OrganisationOverviewContainer from './organisation-overview/organisation-overview.container';
import User from '../../../store/types/user';
import { getPermissionPath, permissionKeys } from '../../../utils/auth/permissions';
import usePermissions from '../../../utils/auth/use-permissions';
import ProtectedRouteContainer from '../../common/protected-route/protected-route.container';
import SidebarMenu from '../../common/app-layout/sidebar/sidebar-menu/sidebar-menu.component';
import EnvironmentsListContainer from './environments-list/environments-list.container';
import AppsListContainer from './apps-list/apps-list.container';
import OrganisationMediaLibraryContainer from './organisation-media-library/organisation-media-library.container';
import OrganisationSpaceContainer from './spaces-list/spaces-list.container';
import SpacesContainer from './spaces-list/spaces-router.container';
import SpaceFormAdd from './spaces-list/space-form/space-form-add.component';
import OrganisationChildrenListContainer from './organisations-list/organisation-list.container';
import OrganisationCustomShortUrlsContainer from './custom-short-url-list/custom-short-url-list.container';
import BrowsersListContainer from './browsers-list/browsers-list.container';
import DevicesUniversalListContainer from './devices-universal-list/devices-universal-list.container';
import UniversalDeviceDetailsContainer from './device-details/universal-device-details/universal-device-details.container';
import TabBar from '../../common/tab-bar/tab-bar.component';
import InstallationGroup from '../../../store/types/installation-group';
import getInstallationAppIcon from '../../../utils/installations/get-installation-app-icon-url';
import getGridappTypeIcon from '../../../utils/gridapp-type-icons';
import DevelopersSection from './developers-section/developers-section.component';
import Marketplace from './marketplace/marketplace.component';
import Billing from './billing/billing.component';
import Plans from './plans/plans.component';
import Contacts from './contacts';
import GridProducts from './grid-products/grid-products.container';
import UserFormFields from './spaces-list/spaces-details/space-details-users/create-users-form.component';
import { InstallationGroupContainer } from './installation-group/installation-group.container';
import Space from '../../../store/types/organisation-space';
import Spinner from '../../common/spinner/spinner.component';
import useGroupAppsByInstallationGroups, {
  noInstallationGroupKey,
} from '../../../utils/group-apps-by-installation-group';
import { Tool, Bell } from 'react-feather';
import Branding from './branding/branding.component';
import Alerts from './alerts/alerts.component';
import DataExport from './data-export/data-export.component';
import Networking from './networking/networking.component';
import NestedMenu from '../../common/nested-menu/nested-menu.component';
import { useGroupedSpacesTreeProps } from '../../../utils/spaces';
import Tree from '../../common/tree/tree.component';
import { Settings } from './settings';
import { isProd } from '../../../utils/env';
import sparkleIcon from '../../../assets/icons/sparkle.svg';
import StoreAI from './store-ai';
import { useAnalyticsParams } from '../../common/use-analytics-params';
import UserManagementContainer from './user-management/user-management.container';
import ContentContainer from './content/content.container';
import useUserSpacePermissions from '../../../store/hooks/spaces/useUserSpacePermissions';

interface OrganisationDetailsProps
  extends RouteComponentProps<{ organisationId: string, spaceId?: string }> {
  organisation: Organisation | null;
  organisationsList: Organisation[];
  fetchApps: ({
    organizationId,
    silent,
  }: {
    organizationId: string;
    silent?: boolean;
  }) => void;
  appsAndGroupsLoaded: boolean;
  apps: OrganisationApp[];
  installationGroups: InstallationGroup[];
  user: User | null;
  spaces: Space[];
}

const organisationMenuKeys = [
  'billing',
  'branding',
  'settings',
  'contacts',
  'custom-short-urls',
  'networking',
  'devices',
  'alerts',
  'environments',
  'grid-products',
  'library',
  'managed-tenants',
  'plans',
  'user-management',
  'data-export',
];

const organisationPatternPaths = [
  '/analytics',
  '/marketplace',
  '/developer',
  '/apps/:appId',
  '/apps',
  '/spaces/:spaceId',
  '/spaces/new',
  '/billing',
  '/branding',
  '/settings',
  '/contacts',
  '/custom-short-urls',
  '/devices',
  '/alerts',
  '/environments',
  '/grid-products',
  '/library/:selectedFolderId?',
  '/managed-tenants',
  '/plans',
  '/users/new',
  '/user-management',
  '/data-export',
  '/networking',
  '/store-ai',
];

const tabs = {
  installations: {
    key: 'installations',
    display: true,
  },
  spaces: {
    key: 'spaces',
    display: true,
  },
  menu: {
    key: 'menu',
    display: true,
  },
};

const getAdjustedTabWidth = (): number | undefined => {
  return Object.keys(omitBy(tabs, (value) => !value.display)).length > 2 ? 55 : undefined;
};

const AppIcon = styled.div<{ src: string }>`
  width: 28px;
  height: 28px;
  margin: auto 10px auto -15px;
  background: url(${(p) => p.src});
  display: inline-block;
  background-size: contain;
  vertical-align: middle;
`;

const OrganisationDetails = (props: OrganisationDetailsProps) => {
  const {
    match,
    location,
    organisation,
    organisationsList,
    fetchApps,
    appsAndGroupsLoaded,
    apps,
    user,
    installationGroups,
    spaces,
  } = props;

  // Support modal
  const [showSupport, setShowSupport] = useState(false);

  const { t } = useTranslation();

  const toggleSupport = useCallback(() => {
    setShowSupport((prevValue) => !prevValue);
  }, []);

  const handleCopyTenantId = useCallback(() => {
    if (organisation) {
      copy(organisation.id);
      message.success(t('copyTenantId'));
    }
  }, [organisation, t]);

  const handleCopyTenantSlug = useCallback(() => {
    if (organisation) {
      copy(organisation.organizationName);
      message.success(t('copyTenantSlug'));
    }
  }, [organisation, t]);

  const handleCopyTenantName = useCallback(() => {
    if (organisation) {
      copy(organisation.displayName);
      message.success(t('copyTenantName'));
    }
  }, [organisation, t]);

  const [tabKey, setTabKey] = useState(tabs.installations.key);
  const [openedInstallationGroupsMenu, setOpenedInstallationGroupsMenu] = useState<
    string[]
  >([]);
  const previousOrganisationMenuPath = useRef<(string | boolean)[]>(['', '', false]);
  const { isAllowed } = usePermissions(match.params.organisationId);
  const history = useHistory();
  const organizationId = organisation ? organisation.id : '';

  const userSpacePermissions = useUserSpacePermissions(organizationId);

  const showInstallationsOverview = useMemo(
    () =>
      isAllowed(permissionKeys.apps.viewAll) ||
      isAllowed(permissionKeys.queues.viewAll) ||
      isAllowed(permissionKeys.queues.viewAnalytics),
    [isAllowed],
  );

  const selectedMenuItemKeys = useMemo(() => {
    const matchedMenuPath = organisationPatternPaths
      .map((path) =>
        matchPath(location.pathname, {
          path: `${match.path}${path}`,
        }),
      )
      .find((pathMatch) => !!pathMatch);

    return matchedMenuPath ? [matchedMenuPath.url] : [];
  }, [location.pathname, match.path]);

  const activeSpaceId = useMemo(() => {
    const matchedUrl = matchPath<{ spaceId: string}>(location.pathname, {
      path: `${match.path}${'/spaces/:spaceId'}`,
    });
    
    if (matchedUrl) {
      return matchedUrl.params.spaceId || null;
    }

    return null;
  }, [location.pathname, match.path]);


  const organisationMenu = useMemo(() => {
    if (organisation) {
      let menu = null;
      const canSwitchOrg = organisationsList.length > 1;
      if (canSwitchOrg) {
        menu = (
          <OrganisationList>
            <Menu
              theme="dark"
              style={{ maxHeight: 'calc(100vh - 55px)', overflowY: 'scroll' }}
            >
              {organisationsList.map((item) => {
                return (
                  <MenuItemSwitchOrg key={item.id}>
                    <Tooltip
                      placement="left"
                      title={item.displayName}
                      overlayClassName="tooltipSidebar"
                    >
                      <Link to={`/organisations/${item.id}`}>
                        <LabelSwitchOrg>
                          <span>{item.displayName}</span>
                        </LabelSwitchOrg>
                      </Link>
                    </Tooltip>
                  </MenuItemSwitchOrg>
                );
              })}
            </Menu>
          </OrganisationList>
        );
      }

      return (
        <OrganisationMenu>
          <Avatar shape="square" icon="shop" size={30} />
            <button type="button" disabled={!appsAndGroupsLoaded}>
              <Tooltip
                placement="bottom"
                title={
                  <TenantTooltipInfoContainer>
                    <TenantTooltipInfo onClick={handleCopyTenantName}>
                      {`${t('name')}: ${organisation.displayName}`}
                      <CopyIcon type="copy" />
                    </TenantTooltipInfo>
                    <TenantTooltipInfo onClick={handleCopyTenantId}>
                      {`${t('id')}: ${organisation.id}`}
                      <CopyIcon type="copy" />
                    </TenantTooltipInfo>
                    <TenantTooltipInfo onClick={handleCopyTenantSlug}>
                      {`${t('slug')}: ${organisation.organizationName}`}
                      <CopyIcon type="copy" />
                    </TenantTooltipInfo>
                  </TenantTooltipInfoContainer>
                }
                overlayStyle={{ width: "fit-content", maxWidth: "fit-content" }}
              >
                <LabelDisplayName>
                  <span>{organisation.displayName}</span>
                </LabelDisplayName>
              </Tooltip>
              {canSwitchOrg && (
                <OrganisationDropdown
                  placement="bottomLeft"
                  trigger={['click']}
                  disabled={!canSwitchOrg}
                  overlay={menu}
                  overlayClassName="organisationSwitchList"
                >
                  <Icon type="swap" />
                </OrganisationDropdown>
              )}
            </button>
        </OrganisationMenu>
      );
    }

    return null;
  }, [
    appsAndGroupsLoaded,
    organisation,
    organisationsList,
    handleCopyTenantId,
    handleCopyTenantName,
    handleCopyTenantSlug,
    t
  ]);

  const groupedApps = useGroupAppsByInstallationGroups({ apps, installationGroups });

  const groupedSpacesTreeProps = useGroupedSpacesTreeProps({ spaces });

  // Google analytics
  useEffect(() => {
    if (organisation) {
      document.cookie = `tenant=${encodeURIComponent(
        organisation.organizationName,
      )}; expires=0; path=/`;
    }
  }, [organisation]);

  useEffect(() => {
    fetchApps({ organizationId, silent: false });
  }, [fetchApps, organizationId]);

  useEffect(() => {
    const currentUrlPath = get(selectedMenuItemKeys, 0, '').split('/');
    const currentMenuPath = get(currentUrlPath, 3);
    const currentMenuItemId = get(currentUrlPath, 4);

    if (!appsAndGroupsLoaded) {
      previousOrganisationMenuPath.current = [currentMenuPath, currentMenuItemId, true];
      return;
    }

    const [
      prevMenuPath,
      prevMenuItemId,
      prevLoadedFlag,
    ] = previousOrganisationMenuPath.current;
    if (
      (appsAndGroupsLoaded && prevLoadedFlag) ||
      (currentMenuPath &&
        prevMenuPath !== currentMenuPath &&
        prevMenuItemId !== currentMenuItemId)
    ) {
      let selectedInstallationGroupIds: string[] = [];
      const isInstallationPath = currentMenuPath === 'apps' && !!currentMenuItemId;

      if (isInstallationPath) {
        const activeInstallation = apps.find((app) => app.id === get(currentUrlPath, 4));

        if (activeInstallation) {
          selectedInstallationGroupIds = [
            activeInstallation.installationGroupId || noInstallationGroupKey,
          ];
        }
      } else {
        selectedInstallationGroupIds = groupedApps[0] ? [groupedApps[0].groupId] : [];
      }

      if (selectedInstallationGroupIds.length) {
        setOpenedInstallationGroupsMenu((prevValue) => [
          ...(!prevLoadedFlag ? prevValue : []),
          ...selectedInstallationGroupIds,
        ]);
      }

      if (
        currentMenuPath &&
        (prevLoadedFlag || prevMenuPath !== currentMenuPath) &&
        organisationMenuKeys.includes(currentMenuPath) &&
        !isInstallationPath
      ) {
        setTabKey(tabs.menu.key);
      } else if (isInstallationPath || prevLoadedFlag) {
        const key = get(tabs, `${currentMenuPath}.display`)
          ? currentMenuPath
          : tabs.installations.key;

        setTabKey(key);
      }
    }

    previousOrganisationMenuPath.current = [currentMenuPath, currentMenuItemId, false];
  }, [apps, selectedMenuItemKeys, groupedApps, appsAndGroupsLoaded]);

  const renderAppRedirect = useCallback(() => {
    if (organisation && match.params.organisationId === organisation.id) {
      let defaultPath = 'analytics';

      if (isAllowed(permissionKeys.marketplace.viewAll)) {
        defaultPath = 'marketplace';
      }

      return <Redirect to={`${match.url}/${defaultPath}`} />;
    }

    return null;
  }, [match.params.organisationId, match.url, organisation, isAllowed]);

  const getOrganisationPermissionPath = useCallback(
    (permissionKey: string) => getPermissionPath(organizationId, permissionKey),
    [organizationId],
  );

  const handleInstallationGroupMenuChange = useCallback((openKeys: string[]) => {
    setOpenedInstallationGroupsMenu(openKeys);
  }, []);

  const { analyticsQueryParams } = useAnalyticsParams();

  const handleSpaceSelect = useCallback((id: string) => {
    history.push(`${match.url}/spaces/${id}${analyticsQueryParams}`);
  }, [history, match, analyticsQueryParams]);

  if (!organisation) {
    return <ErrorView />;
  }

  return (
    <>
      <AppLayout.Navbar title={organisation.displayName} />
      <AppLayout>
        <AppLayout.Sidebar header={organisationMenu}>
          {!appsAndGroupsLoaded ? (
            <Overlay>
              <Spinner />
            </Overlay>
          ) : (
            <>
              {/* TODO: figure out how to feed theme to styled components, it's blacklisted prop */}
              <SidebarMenu selectedKeys={selectedMenuItemKeys}>
                {(organisation.customOverviewDashboard || organisation.dataResidency) &&
                  isAllowed(permissionKeys.organizations.viewAllAnalytics) &&
                  userSpacePermissions.hasAccessToAllSpaces && (
                    <Menu.Item key={`${match.url}/analytics`}>
                      <Link to={`${match.url}/analytics${analyticsQueryParams}`}>
                        <Icon type="dashboard" />
                        {t('analytics')}
                      </Link>
                    </Menu.Item>
                  )}
                {isAllowed(permissionKeys.contacts.viewAll) && (
                  <Menu.Item key={`${match.url}/contacts`}>
                    <Link to={`${match.url}/contacts`}>
                      <Icon type="contacts" />
                      {t('tenantBarOptionsContacts')}
                    </Link>
                  </Menu.Item>
                )}
                {isAllowed(permissionKeys.gridProducts.viewAll) && (
                  <Menu.Item key={`${match.url}/grid-products`}>
                    <Link to={`${match.url}/grid-products`} data-testid="organisation-details-products-tab">
                      <Icon type="shop" />
                      <span>{t('gridProducts.title')}</span>
                    </Link>
                  </Menu.Item>
                )}
                {isAllowed(permissionKeys.media.viewAll) && (
                  <Menu.Item key={`${match.url}/library`} data-testid="media-tab">
                    <Link to={`${match.url}/library`}>
                      <Icon type="picture" />
                      <span>{t('files')}</span>
                    </Link>
                  </Menu.Item>
                )}
                {isAllowed(permissionKeys.devices.viewAll) && (
                  <Menu.Item key={`${match.url}/devices`}>
                    <Link to={`${match.url}/devices`}>
                      <Icon type="robot" />
                      <span>{t('universalDevices')}</span>
                    </Link>
                  </Menu.Item>
                )}
                {isAllowed(permissionKeys.gridApps.viewAll) && (
                  <Menu.Item key={`${match.url}/marketplace`}>
                    <Link to={`${match.url}/marketplace`}>
                      <Icon type="shopping-cart" />
                      {t('marketplace')}
                    </Link>
                  </Menu.Item>
                )}
                {(!isProd() || (user && user.isSysadmin)) && isAllowed(permissionKeys.storeAIAssistants.viewAll) && (
                  <Menu.Item key={`${match.url}/store-ai`}>
                    <Link to={`${match.url}/store-ai`}>
                      <Icon src={sparkleIcon} size={14} />
                      {t('storeAI.title')}
                    </Link>
                  </Menu.Item>
                )}
                {isAllowed(permissionKeys.developerPAT.create) && (
                  <Menu.Item key={`${match.url}/developer`}>
                    <Link to={`${match.url}/developer`}>
                      <Icon type="code" />
                      {t('developer')}
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item key="support">
                  <SupportButton
                    role="button"
                    tabIndex={0}
                    onClick={toggleSupport}
                    aria-hidden="true"
                  >
                    <Icon type="question-circle" />
                    {t('support')}
                  </SupportButton>
                </Menu.Item>
                <TabBarStyled
                  activeKey={tabKey}
                  onChange={(key) => setTabKey(key)}
                  tabBarGutter={1}
                  tabMinWidth={getAdjustedTabWidth()}
                >
                  {showInstallationsOverview && (
                    <Tabs.TabPane
                      tab={<TabTitleStyled>{t('installations')}</TabTitleStyled>}
                      key={tabs.installations.key}
                    >
                      <NestedMenu
                        menuProps={{
                          selectedKeys: selectedMenuItemKeys,
                          openKeys: openedInstallationGroupsMenu,
                          mode: "inline",
                          theme: "dark",
                          onOpenChange: handleInstallationGroupMenuChange,
                        }}
                        emptyListTitle={t('noAppsFound')}
                        data={groupedApps.map((appsGroup: any) => {
                          return {
                            key: `${match.url}/installation-group/${appsGroup.groupId}`,
                            className: tabs.installations.key,
                            enabled: true,
                            title: (
                              <Tooltip
                                placement="left"
                                title={appsGroup.groupName}
                                overlayClassName="tooltipSidebar"
                              >
                                <LabelInstallationGroupContainer>
                                  <LabelInstallationGroup>
                                    <span>{appsGroup.groupName}</span>
                                  </LabelInstallationGroup>{' '}
                                  <EditInstallationGroupAndNumberContainer>
                                    {isAllowed(permissionKeys.apps.create) &&
                                    appsGroup.groupId !== noInstallationGroupKey ? (
                                      <Link
                                        to={`${match.url}/installation-group/${
                                          appsGroup.groupId
                                        }`}
                                      >
                                        <PenIcon type="edit" />
                                      </Link>
                                    ) : (
                                      <PenIconPlaceholder />
                                    )}
                                    <BadgeStyled
                                      count={appsGroup.apps.length || '-'}
                                    />
                                  </EditInstallationGroupAndNumberContainer>
                                </LabelInstallationGroupContainer>
                              </Tooltip>
                            ),
                            children: appsGroup.apps.map((app: OrganisationApp) => {
                              return {
                                key: `${match.url}/apps/${app.id}`,
                                content: (
                                  <Tooltip
                                    placement="left"
                                    title={app.appName}
                                    overlayClassName="tooltipSidebar"
                                  >
                                    <Link to={`${match.url}/apps/${app.id}${analyticsQueryParams}`}>
                                      <AppIcon src={getInstallationAppIcon(app)} />
                                      <LabelInstallation>
                                        <span>{app.displayName}</span>
                                      </LabelInstallation>
                                      <Icon type={getGridappTypeIcon(app.deviceType)} />
                                    </Link>
                                  </Tooltip>
                                ),
                              };
                            })
                          }
                        })}
                      />
                    </Tabs.TabPane>
                  )}
                  <Tabs.TabPane
                    tab={<TabTitleStyled>{t('spaces')}</TabTitleStyled>}
                    key={tabs.spaces.key}
                  >
                    {isAllowed(permissionKeys.spaces.create) && (
                      <Link to={`${match.url}/spaces/new`}>
                        <AddLocationButton icon="plus">
                          {t('createSpace')}
                        </AddLocationButton>
                      </Link>
                    )}
                    <Tree tree={groupedSpacesTreeProps || []} onSelect={handleSpaceSelect} activeId={activeSpaceId} />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={<TabTitleStyled>{t('menu')}</TabTitleStyled>}
                    key={tabs.menu.key}
                  >
                    <Menu selectedKeys={selectedMenuItemKeys} mode="inline" theme="dark">
                      <MenuItemGroupStyled className="menu">
                        {isAllowed(permissionKeys.plans.viewAll) && (
                          <Menu.Item key={`${match.url}/plans`} data-testid="organisation-details-plan-tab">
                            <Link to={`${match.url}/plans`}>
                              <Icon type="file-text" />
                              <span>{t('plans.title')}</span>
                            </Link>
                          </Menu.Item>
                        )}
                        {isAllowed(permissionKeys.subscriptions.viewAll) && (
                          <Menu.Item key={`${match.url}/billing`} data-testid="organisation-details-billing-tab">
                            <Link to={`${match.url}/billing`}>
                              <Icon type="credit-card" />
                              <span>{t('billing.title')}</span>
                            </Link>
                          </Menu.Item>
                        )}
                        {isAllowed(permissionKeys.organizations.viewAll) && (
                          <Menu.Item key={`${match.url}/branding`} data-testid="organisation-details-branding-tab">
                            <Link to={`${match.url}/branding`}>
                              <Icon component={() => <Tool size={14} />} />
                              <span>{t('branding.title')}</span>
                            </Link>
                          </Menu.Item>
                        )}
                        {isAllowed(permissionKeys.integrations.viewAll) && (
                          <Menu.Item key={`${match.url}/settings`} data-testid="organisation-details-settings-tab">
                            <Link to={`${match.url}/settings`}>
                              <Icon type="setting" />
                              <span>{t('settings')}</span>
                            </Link>
                          </Menu.Item>
                        )}
                        {isAllowed(permissionKeys.analyticsExportJob.viewAll) && (
                          <Menu.Item key={`${match.url}/data-export`}>
                            <Link to={`${match.url}/data-export`}>
                              <Icon type="download" />
                              <span>{t('dataExport.title')}</span>
                            </Link>
                          </Menu.Item>
                        )}
                        {isAllowed(permissionKeys.users.viewAll) && (
                          <Menu.Item key={`${match.url}/user-management`} data-testid="organisation-details-user-management-tab">
                            <Link to={`${match.url}/user-management`}>
                              <Icon type="user" />
                              <span>{t('userManagement.userManagementHeading')}</span>
                            </Link>
                          </Menu.Item>
                        )}
                        {organisation.isPartnerOrganization &&
                          !organisation.parentOrganizationId &&
                          isAllowed(permissionKeys.organizations.viewAll) && (
                            <Menu.Item key={`${match.url}/managed-tenants`} data-testid="organisation-details-managed-tenants-tab">
                              <Link to={`${match.url}/managed-tenants`}>
                                <Icon type="solution" />
                                <span>{t('managedTenants')}</span>
                              </Link>
                            </Menu.Item>
                          )}
                        {isAllowed(permissionKeys.environments.viewAll) && (
                          <Menu.Item key={`${match.url}/environments`} data-testid="organisation-details-environments-tab">
                            <Link to={`${match.url}/environments`}>
                              <Icon type="cloud" />
                              <span>{t('environments')}</span>
                            </Link>
                          </Menu.Item>
                        )}
                        <Menu.Item key={`${match.url}/alerts`}>
                          <Link to={`${match.url}/alerts`} data-testid="organisation-details-alerts-tab">
                            <Icon component={() => <Bell size={14} />} />
                            <span>{t('alerts.title')}</span>
                          </Link>
                        </Menu.Item>
                        {isAllowed(permissionKeys.networkWhitelistRules.viewAll) && (
                          <Menu.Item key={`${match.url}/networking`} data-testid="organisation-details-networking-tab">
                            <Link to={`${match.url}/networking`}>
                              <Icon type="apartment" />
                              <span>{t('networking.title')}</span>
                            </Link>
                          </Menu.Item>
                        )}
                        {isAllowed(permissionKeys.customShortUrls.viewAll) && (
                          <Menu.Item key={`${match.url}/custom-short-urls`}>
                            <Link to={`${match.url}/custom-short-urls`}>
                              <Icon type="qrcode" />
                              <span>{t('customShortUrls')}</span>
                            </Link>
                          </Menu.Item>
                        )}
                      </MenuItemGroupStyled>
                    </Menu>
                  </Tabs.TabPane>
                </TabBarStyled>
              </SidebarMenu>
            </>
          )}
        </AppLayout.Sidebar>
        <AppLayout.Content>
          {appsAndGroupsLoaded && (
            <Switch>
              <Route exact path={match.path} render={renderAppRedirect} />
              <ProtectedRouteContainer
                path={`${match.path}/apps/:appId`}
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.apps.viewSingle,
                )}
                component={AppDetailsContainer}
              />
              <ProtectedRouteContainer
                path={`${match.path}/devices/v2/:deviceUuid`}
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.devices.viewSingle,
                )}
                component={AzureDeviceDetailsContainer}
              />
              <ProtectedRouteContainer
                path={`${match.path}/devices/v3/:deviceUuid`}
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.devices.viewSingle,
                )}
                component={UniversalDeviceDetailsContainer}
              />
              <ProtectedRouteContainer
                path={`${match.path}/devices/:deviceUuid`}
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.devices.viewSingle,
                )}
                component={ClassicDeviceDetailsContainer}
              />
              <ProtectedRouteContainer
                path={`${match.path}/devices`}
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.devices.viewAll,
                )}
                component={DevicesUniversalListContainer}
              />
              {user && user.isSysadmin && (
                <ProtectedRouteContainer
                  permissionPath={getOrganisationPermissionPath(
                    permissionKeys.browsers.viewAll,
                  )}
                  path={`${match.path}/browsers`}
                  component={BrowsersListContainer}
                />
              )}
              <ProtectedRouteContainer
                exact
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.apps.viewAll,
                )}
                path={`${match.path}/apps`}
                component={AppsListContainer}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.spaces.create,
                )}
                path={`${match.path}/spaces/new`}
                component={SpaceFormAdd}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.spaces.viewAll,
                )}
                path={`${match.path}/spaces/:spaceId`}
                component={SpacesContainer}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.spaces.viewAll,
                )}
                path={`${match.path}/users/new`}
                component={UserFormFields}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.spaces.viewAll,
                )}
                path={`${match.path}/spaces`}
                component={OrganisationSpaceContainer}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.apps.viewAll,
                )}
                path={`${match.path}/installation-group/:groupId`}
                component={InstallationGroupContainer}
              />

              <ProtectedRouteContainer
                path={`${match.path}/library/:selectedFolderId?`}
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.media.viewAll,
                )}
                component={OrganisationMediaLibraryContainer}
              />
              <ProtectedRouteContainer
                path={`${match.path}/content`}
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.media.viewAll,
                )}
                component={ContentContainer}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.gridApps.create,
                )}
                path={`${match.path}/developer`}
                component={DevelopersSection}
              />
              {(organisation.customOverviewDashboard || organisation.dataResidency) && (
                <ProtectedRouteContainer
                  permissionPath={getOrganisationPermissionPath(
                    permissionKeys.organizations.viewAllAnalytics,
                  )}
                  path={`${match.path}/analytics`}
                  component={OrganisationOverviewContainer}
                />
              )}
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.environments.viewAll,
                )}
                path={`${match.path}/environments`}
                component={EnvironmentsListContainer}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.organizations.viewAll,
                )}
                path={`${match.path}/managed-tenants`}
                component={OrganisationChildrenListContainer}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.customShortUrls.viewAll,
                )}
                path={`${match.path}/custom-short-urls`}
                component={OrganisationCustomShortUrlsContainer}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.userRoles.viewAll,
                )}
                path={`${match.path}/user-management`}
                component={UserManagementContainer}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.marketplace.viewAll,
                )}
                path={`${match.path}/marketplace`}
                component={Marketplace}
              />
              {(!isProd() || (user && user.isSysadmin)) && (
                <ProtectedRouteContainer
                  permissionPath={getOrganisationPermissionPath(
                    permissionKeys.storeAIAssistants.viewAll,
                  )}
                  path={`${match.path}/store-ai`}
                  component={StoreAI}
                />
              )}
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.contacts.viewAll,
                )}
                path={`${match.path}/contacts`}
                component={Contacts}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.gridProducts.viewAll,
                )}
                path={`${match.path}/grid-products`}
                component={GridProducts}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.plans.viewAll,
                )}
                path={`${match.path}/plans`}
                component={Plans}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.subscriptions.viewAll,
                )}
                path={`${match.path}/billing`}
                component={Billing}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.organizations.viewAll,
                )}
                path={`${match.path}/branding`}
                component={Branding}
              />
                <ProtectedRouteContainer
                  // TO DO: Create a separate settings permission
                  //  For now, we'll use integrations bec there are no other submenus under settings
                  permissionPath={getOrganisationPermissionPath(
                    permissionKeys.integrations.viewAll
                  )}
                  path={`${match.path}/settings`}
                  component={Settings} /><ProtectedRouteContainer
                    permissionPath={getOrganisationPermissionPath(
                      permissionKeys.alerts.viewAll
                    )}
                    path={`${match.path}/alerts`}
                    component={Alerts} />
                <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.analyticsExportJob.viewAll,
                )}
                path={`${match.path}/data-export`}
                component={DataExport}
              />
              <ProtectedRouteContainer
                permissionPath={getOrganisationPermissionPath(
                  permissionKeys.networkWhitelistRules.viewAll,
                )}
                path={`${match.path}/networking`}
                component={Networking}
              />
            </Switch>
          )}
        </AppLayout.Content>
        <AppLayout.Footer />
      </AppLayout>
      <Modal
        title="Help & Support"
        visible={showSupport}
        onOk={toggleSupport}
        onCancel={toggleSupport}
        footer={null}
      >
        <SupportContent>
          <img
            src="https://media.giphy.com/media/5hgMcnAkBVcARGsGWu/giphy.gif"
            alt="Help gif"
          />
          <p>
            The fastest way to speak to someone who can help is on the{' '}
            <strong>Ombori Grid Slack</strong>
          </p>
          <Button
            size="large"
            type="primary"
            href="https://join.slack.com/t/slack-pgo5586/shared_invite/zt-s1ajca83-k8i1f2mqgCMD0vDfpCk4Bg"
            target="_blank"
          >
            Join our Slack community
          </Button>
          <p>
            Or{' '}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://ombori.com/contact"
            >
              Contact Ombori
            </a>
          </p>
        </SupportContent>
      </Modal>
    </>
  );
};

const PenIcon = styled(Icon)`
  color: white;
  text-decoration: none;
`;

const SupportButton = styled.span`
  display: block;
`;

const SupportContent = styled.div`
  display: block;
  text-align: center;

  img {
    max-width: 320px;
  }

  p {
    max-width: 320px;
    margin: 24px auto;
  }
`;

const OrganisationMenu = styled.div`
  width: 300px;
  float: left;
  border-bottom: solid 1px #181818;
  height: 55px;
  line-height: 52px;
  .ant-avatar {
    margin-left: 15px;
  }
  > button {
    width: 250px;
    text-align: left;
    border: 0;
    outline: 0;
    cursor: pointer;
    color: #fff;
    background-color: #202020;
    transition: background-color 0.3s;
    margin-left: 5px;
    height: 100%;
    > i {
      height: 40px;
      width: 40px;
      float: right;
      margin-top: 6px;
      line-height: 45px;
    }
    > i:hover {
      background-color: rgba(255, 255, 255, 0.04);
    }
  }
`;

const OrganisationList = styled.div`
  .ant-dropdown {
    top: 55px;
    left: 0px;
    width: 300px;
  }
`;

const OrganisationDropdown = styled(Dropdown)`
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  height: 50px;
`;

const TabTitleStyled = styled.span`
  color: rgba(255, 255, 255, 0.65);
`;

const ClipText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 195px;
  vertical-align: middle;
  height: 100%;
  padding: 11px 0;

  > span {
    overflow: hidden;
    line-height: 20px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const LabelDisplayName = styled(ClipText)`
  padding: 0;
  color: #fff;
  height: unset;
`;

const TenantTooltipInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
`;

const TenantTooltipInfo = styled(ClipText)`
  color: #fff;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  width: 100%;
`;

const CopyIcon = styled(Icon)`
  margin-left: 4px;
  cursor: pointer;
`;

const LabelInstallation = styled(ClipText)`
  width: 170px !important;
  &:hover {
    color: white;
  }
`;

const LabelInstallationGroup = styled(ClipText)`
  width: 160px !important;
  white-space: normal;
  padding: 0;
  span {
    color: white;
  }
`;

const MenuItemSwitchOrg = styled(Menu.Item)`
  white-space: normal;
  height: fit-content !important;
`;

const LabelSwitchOrg = styled(ClipText)`
  margin-left: 0px;
  width: 260px !important;
`;

const MenuItemGroupStyled = styled(Menu.ItemGroup)`
  &.${({ className }) => className}[role='menuitem'] > a {
    > span {
      width: 75%;
      display: inline-block;
      overflow-y: clip;
      text-overflow: ellipsis;
    }
    > i {
      float: left;
      margin: 15px 10px auto auto;
    }
  }
` as any;

const BadgeStyled = styled(Badge)`
  position: relative;
  vertical-align: middle !important;
  min-width: 34px;
  font-size: 11px;

  .ant-badge-count {
    box-shadow: none;
    background-color: #363636;
  }
`;

const TabBarStyled = styled(TabBar)<{ tabMinWidth?: number }>`
  .ant-tabs-bar {
    margin: 16px auto 0px auto !important;
    border-bottom: 1px solid !important;
  }
  .ant-tabs-bar > .ant-tabs-nav-container-scrolling {
    padding: 0px;
  }

  .ant-tabs-tab {
    min-width: ${({ tabMinWidth }) => tabMinWidth || 137}px;
    text-align: center;
  }
`;

const AddLocationButton = styled(Button)`
  background: transparent;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  padding-left: 26px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 12px;
  align-items: center;
  border: none;

  :hover, :active, :focus {
    text-decoration: none;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;

    &:after {
      border: none !important;
      box-shadow: none !important;
    }
  }

  i {
    margin-top: 2px;
  }
}
` as any;

const LabelInstallationGroupContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  min-height: 40px;
  align-content: center;
  flex-wrap: wrap;

  i {
    visibility: hidden;
  }

  &:hover i {
    visibility: visible;
  }
`;

const EditInstallationGroupAndNumberContainer = styled.div`
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PenIconPlaceholder = styled.div`
  width: 24px;
`;

export default OrganisationDetails;
